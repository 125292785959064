.header {
    padding: 0px;
    margin: 0px;
}

/* .header nav ul {
    list-style-type: none;
    padding: 0;
}

.header nav ul li {
    display: inline;
    margin: 0 15px;
}

.header nav ul li a {
    color: #fff;
    text-decoration: none;
}

.header nav ul li a:hover {
    text-decoration: underline;
} */
